<template>
    <div>
        <div>
            <div class="statusWarp" v-if="initData.AdStatus">
                <el-tooltip effect="light" content="有拒登" placement="bottom" v-if="(level !== 'ad' && scope.row.exist_disapproved)">
                    <span class="el-icon-warning-outline text-danger mr-5"></span>
                </el-tooltip>
                <span v-if="scope.row.effective_status != 'DELETED' && scope.row.effective_status != 'ARCHIVED'"
                    :class="['pointer', scope.row.effective_status]"></span>
                <span :class="['name', `name${scope.row.effective_status}`]">{{
                    initData.AdStatus.filter((v) => v.key == scope.row.effective_status)[0].desc
                }}</span>
                <el-tooltip placement="bottom" width="600" trigger="hover"
                    v-if="scope.row.status_hints && scope.row.status_hints.length" :enterable="true" effect="light"
                    popper-class="rule-tip">
                    <div slot="content">
                        <div class="rule-score-wrap" v-for="(item, index) in scope.row.status_hints" :key="index">
                            <div>
                                <i class="el-icon-warning" v-if="item.level == 'WARN'"></i>
                                <i class="el-icon-error" v-if="item.level == 'ERROR'"></i>
                                <span>{{ item.message }}</span>
                            </div>
                            <div v-if="item.alertId" style="margin-left: 5px">
                                <el-button v-if="item.alertScore == null || item.alertScore == 1" type="text" size="mini"
                                    :disabled="item.alertScore == 1" @click="
                                        reviewScore({
                                            curTableRow: scope.row,
                                            curTableIndex: scope.$index,
                                            curRow: item,
                                            curIndex: index,
                                            flag: 1,
                                        })
                                        ">准确</el-button>

                                <el-button v-if="item.alertScore == null || item.alertScore === 0" type="text" size="mini"
                                    :disabled="item.alertScore === 0" @click="
                                        reviewScore({
                                            curTableRow: scope.row,
                                            curTableIndex: scope.$index,
                                            curRow: item,
                                            curIndex: index,
                                            flag: 0,
                                        })
                                        ">不准确</el-button>
                            </div>
                        </div>
                    </div>
                    <i class="el-icon-info" :class="{
                        c6D0: scope.row.status_hints.find((item) => item.level == 'SUGGESTION'),
                        cF80: scope.row.status_hints.find((item) => item.level == 'WARN'),
                        cF33: scope.row.status_hints.find((item) => item.level == 'ERROR'),
                    }"></i>
                </el-tooltip>
            </div>
            <div class="dp-flex align-item-c AIRecommendation" v-if="scope.row.ai_suggestion">
                <!-- <img
                src="@/assets/icon/AI.jpg"
                alt=""
              /> -->
                <!-- <svg
                class="icon"
                aria-hidden="true"
              >
                <use :xlink:href="AiSuggestionTypes[scope.row.ai_suggestion.type]"></use>
              </svg> -->
                <span :class="[
                    'iconfont',
                    AiSuggestionTypes[scope.row.ai_suggestion.type].icon,
                    AiSuggestionTypes[scope.row.ai_suggestion.type].class,
                    fromNow(scope.row.ai_suggestion.suggestion_time) > 30 ? 'cBBB' : '',
                    'mr-5',
                ]"></span>
                <span :class="{
                    [AiSuggestionTypes[scope.row.ai_suggestion.type].class]: true,
                    cBBB: fromNow(scope.row.ai_suggestion.suggestion_time) > 30,
                }">
                    {{ scope.row.ai_suggestion.message }} {{ formatterTime(scope.row.ai_suggestion.suggestion_time) }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { stringify } from 'qs';
export default {
    props: {
        scope: {
            type: Object,
            default: () => { }
        },
        initData: {
            type: Object,
            default: () => { }
        },
        level:{
            type:String,
            default: () => 'campaign'
        }
    },
    data() {
        return {

        }
    },
    methods: {
        fromNow(time) {
            const start = moment(time, 'YYYY-MM-DD HH:mm:ss');
            const end = moment();
            const diff = end.diff(start);
            return moment.duration(diff).asMinutes();
        },
        formatterTime(timeStr) {
            return moment(timeStr).format('HH:mm');
        },
        reviewScore(obj) {
            this.$emit('reviewScore', obj)
        }
    }
}
</script>
<style lang="scss" scoped>
.AIRecommendation {
    font-size: 12px;

    .c80D580 {
        color: #80d580;
    }

    .cFB6 {
        color: #fb6;
    }

    .cF88 {
        color: #f88;
    }

    .c99bbff {
        color: #99bbff;
    }

    .cBBB {
        color: #bbbbbb;
    }

    img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }
}
</style>