var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.initData.AdStatus)?_c('div',{staticClass:"statusWarp"},[((_vm.level !== 'ad' && _vm.scope.row.exist_disapproved))?_c('el-tooltip',{attrs:{"effect":"light","content":"有拒登","placement":"bottom"}},[_c('span',{staticClass:"el-icon-warning-outline text-danger mr-5"})]):_vm._e(),(_vm.scope.row.effective_status != 'DELETED' && _vm.scope.row.effective_status != 'ARCHIVED')?_c('span',{class:['pointer', _vm.scope.row.effective_status]}):_vm._e(),_c('span',{class:['name', `name${_vm.scope.row.effective_status}`]},[_vm._v(_vm._s(_vm.initData.AdStatus.filter((v) => v.key == _vm.scope.row.effective_status)[0].desc))]),(_vm.scope.row.status_hints && _vm.scope.row.status_hints.length)?_c('el-tooltip',{attrs:{"placement":"bottom","width":"600","trigger":"hover","enterable":true,"effect":"light","popper-class":"rule-tip"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.scope.row.status_hints),function(item,index){return _c('div',{key:index,staticClass:"rule-score-wrap"},[_c('div',[(item.level == 'WARN')?_c('i',{staticClass:"el-icon-warning"}):_vm._e(),(item.level == 'ERROR')?_c('i',{staticClass:"el-icon-error"}):_vm._e(),_c('span',[_vm._v(_vm._s(item.message))])]),(item.alertId)?_c('div',{staticStyle:{"margin-left":"5px"}},[(item.alertScore == null || item.alertScore == 1)?_c('el-button',{attrs:{"type":"text","size":"mini","disabled":item.alertScore == 1},on:{"click":function($event){return _vm.reviewScore({
                                        curTableRow: _vm.scope.row,
                                        curTableIndex: _vm.scope.$index,
                                        curRow: item,
                                        curIndex: index,
                                        flag: 1,
                                    })}}},[_vm._v("准确")]):_vm._e(),(item.alertScore == null || item.alertScore === 0)?_c('el-button',{attrs:{"type":"text","size":"mini","disabled":item.alertScore === 0},on:{"click":function($event){return _vm.reviewScore({
                                        curTableRow: _vm.scope.row,
                                        curTableIndex: _vm.scope.$index,
                                        curRow: item,
                                        curIndex: index,
                                        flag: 0,
                                    })}}},[_vm._v("不准确")]):_vm._e()],1):_vm._e()])}),0),_c('i',{staticClass:"el-icon-info",class:{
                    c6D0: _vm.scope.row.status_hints.find((item) => item.level == 'SUGGESTION'),
                    cF80: _vm.scope.row.status_hints.find((item) => item.level == 'WARN'),
                    cF33: _vm.scope.row.status_hints.find((item) => item.level == 'ERROR'),
                }})]):_vm._e()],1):_vm._e(),(_vm.scope.row.ai_suggestion)?_c('div',{staticClass:"dp-flex align-item-c AIRecommendation"},[_c('span',{class:[
                'iconfont',
                _vm.AiSuggestionTypes[_vm.scope.row.ai_suggestion.type].icon,
                _vm.AiSuggestionTypes[_vm.scope.row.ai_suggestion.type].class,
                _vm.fromNow(_vm.scope.row.ai_suggestion.suggestion_time) > 30 ? 'cBBB' : '',
                'mr-5',
            ]}),_c('span',{class:{
                [_vm.AiSuggestionTypes[_vm.scope.row.ai_suggestion.type].class]: true,
                cBBB: _vm.fromNow(_vm.scope.row.ai_suggestion.suggestion_time) > 30,
            }},[_vm._v(" "+_vm._s(_vm.scope.row.ai_suggestion.message)+" "+_vm._s(_vm.formatterTime(_vm.scope.row.ai_suggestion.suggestion_time))+" ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }