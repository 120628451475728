<template>
  <div class="bidDialog">
    <el-dialog
      title="定时改出价"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="30%"
      @opened="show"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item
          label="开启日期"
          prop="dateVal"
        >
          <el-date-picker
            v-model="formData.dateVal"
            type="datetime"
            placeholder="选择日期"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="row.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS' ? '最低roas' : '出价金额'"
          prop="bidAmount"
        >
          <el-input
            v-model="formData.bidAmount"
            @input="
              (val) => {
                handleInput(val, formData, 'bidAmount');
              }
            "
          ></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { settingAdScheduleUpdateBidTask } from '@/api/adManagement.js';
import { TextHighlightRules } from 'vue-json-editor/assets/jsoneditor';
export default {
  props: {
    level: {
      type: String,
      default: 'campaign',
    },
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      formData: {
        dateVal: '',
        bidAmount: '',
      },
      platform: 'facebook',
      // 设置不能选则当天之前的时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
        start: '00:00',
        step: '00:30',
        end: '23:30',
      },
      loading: false,
      rules: {
        dateVal: { required: true, message: '请选择开启日期', trigger: 'blur' },
        bidAmount: { required: true, message: '请输入竞价金额', trigger: 'blur' },
      },
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    valueName() {
      let name = '';
      switch (this.row.bid_strategy) {
        case 'LOWEST_COST_WITHOUT_CAP':
          name = '自动竞价';
          break;
        case 'COST_CAP':
          name = '目标费用';
          break;
        case 'LOWEST_COST_WITH_BID_CAP':
          name = '手动出价';
          break;
      }
      return name;
    },
  },
  methods: {
    show(v) {
      if (this.row.open_schedule) {
        const bid = this.row.open_schedule.find((item) => item.type == 3);
        if (bid) {
          this.formData.dateVal = bid.timeStr;
          this.formData.bidAmount = bid.bidAmount;
          this.dialogVisible = true;
          return;
        }
      }
      this.formData.bidAmount =
        this.row.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS'
          ? this.row.roas_average_floor
          : this.$store.getters.toFormatedMoney(this.row.bid_amount).value;
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let request = {
            platform: this.platform,
            level: this.level,
            handleTime: this.formData.dateVal,
            bidDecimal: this.formData.bidAmount,
            adInfo: {
              adAccountId: this.row.adAccountId || this.$store.getters.accountId,
              id: this.row.id, //广告组id,
              campaignId: this.row.campaignId,
              adGroupId: this.row.adGroupId,
              adGroupAdId: this.row.adGroupAdId,
            },
            gimpUserId: this.userInfo.data.id,
          };
          this.loading = true;
          settingAdScheduleUpdateBidTask(JSON.stringify(Object.assign(request, {})))
            .then((res) => {
              this.loading = false;
              this.hide();
              if (res.code === 0) {
                this.$message.success('定时修改出价已打开！');
                this.$emit(
                  'settingComplete',
                  this.formData,
                  3,
                  this.row.id,
                  this.formData.bidAmount,
                  res.data.id,
                  res.data,
                );
              }
            })
            .catch(() => {
              this.loading = false;
              this.hide();
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //处理输入框两位小数
    handleInput(value, arrName, keyName) {
      // console.log(value, arrName, keyName);
      this.$set(
        arrName,
        keyName,
        value
          .replace(/[^\d.]/g, '')
          .replace(/^\./g, '')
          .replace(/\.{2,}/g, '.')
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
          .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'),
      );
    },
  },
};
</script>

<style lang="scss">
.el-table td.el-table__cell div.el-dialog__header {
  text-align: left;
}
.bidDialog {
  .el-input,
  .el-select {
    width: 100%;
  }
}
</style>
