<template>
    <div>
        <div v-if="row.today_local_order">
            <!-- {{ row.today_local_order }} -->
            {{ row.today_local_order.validOrderCount }} / {{ row.today_local_order.orderCount }}
        </div>
        <div v-else> -- </div>
    </div>
</template>
<script>
    export default {
        props:{
            row:{
                type:Object,
                default:()=>{}
            }
        },
        data(){
            return {

            }
        }
    }
</script>