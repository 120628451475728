<template>
  <div>
    <el-popover placement="top" width="480" v-model="visible">
      <el-row justify="center" align="center" type="flex">
        <el-col :span="18">
          
          <el-select v-model="value1" multiple placeholder="请选择" size="mini" value-key="text" :multiple-limit="5" style="width:94%">
            <el-option
              v-for="item in tagsList"
              :key="item.text"
              :label="`${item.text}${item.quick?'(快速)':''}`"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <div style="text-align: right; margin: 0; display: inline-block">
            <el-button size="mini" type="text" @click="visible = false"
              >取消</el-button
            >
            <el-button type="primary" size="mini" @click="setTag"
              >确定</el-button
            >
          </div>
        </el-col>
      </el-row>
      <div slot="reference" class="tags-wrap">
          <el-tag v-for="tag in tags" :key="tag.text" size="mini" @click.stop="tagClick(tag)">{{ `${tag.text}${tag.quick?'(快速)':''}` }}</el-tag>
          <el-button  type="text" icon="el-icon-edit"></el-button>
      </div>
    </el-popover>
  </div>
</template>

<script>
import {fnCSTToYmd,adPickerOptions} from "@/utils/utils";
export default {
    props:{
        adPickerOptions:{
            type:Object,
            default:()=>{}
        }
    },
  data() {
    return {
      visible: false,
      value1: JSON.parse(localStorage.getItem('dateTag')) || [],
      tags:JSON.parse(localStorage.getItem('dateTag')) || []
    };
  },
  computed:{
      tagsList(){
          let list = this.adPickerOptions.shortcuts;
          list = list.filter(v=>v.quick).concat(list.filter(v=>!v.quick))
          return list.map(v=>{
              return {text:v.text,quick:v.quick}
          })
      }
  },
  methods: {
    setTag() {
        this.tags = this.value1;
        this.visible = false;
        console.log(this.tags);
        localStorage.setItem('dateTag',JSON.stringify(this.tags))
    },
    tagClick(tag){
        let dateList = this.adPickerOptions.shortcuts.find(v=>v.text == tag.text).onClick();
        console.log(dateList);
        let dateRange = {};
        if(localStorage.getItem('adDateRange')){
            dateRange = {...JSON.parse(localStorage.getItem('adDateRange'))}
        }
        dateRange.dateRange = [fnCSTToYmd(new Date(dateList[0]),{tag:1}),fnCSTToYmd(new Date(dateList[1]),{tag:1})];
        this.$emit('setDate',dateRange)
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-wrap{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;
    .el-tag{
        margin-right: 10px;
        cursor: pointer;
    }
}
</style>