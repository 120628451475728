<template>
  <el-dialog
    title="定时改预算"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    width="30%"
  >
    <el-form
      ref="form"
      :model="formData"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item
        label="日期"
        prop="dateVal"
      >
        <el-date-picker
          v-model="formData.dateVal"
          type="datetime"
          placeholder="选择日期"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="类型">
        <el-input
          :value="
            row.daily_budget || (row.ad && row.ad.campaignDailyBudget) || (row.ad && row.ad.adGroupDailyBudget)
              ? '日预算'
              : '总预算'
          "
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item
        label="预算金额"
        prop="budgetAmount"
      >
        <el-input
          v-model="formData.budgetAmount"
          @input="
            (val) => {
              handleInput(val, formData, 'budgetAmount');
            }
          "
        ></el-input>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="submit"
        :loading="loading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { settingAdScheduleUpdateBudgetTask } from '@/api/adManagement.js';
// import { settingAdScheduleUpdateBudgetTaskMobile } from '@/api/multiPlatform.js';
export default {
  props: {
    setTimeIds: {
      // 开启的 id集合
      type: Array,
      default: () => {
        return [];
      },
    },
    newAdScheduleOpenTaskType: {
      type: Array,
      default: () => [],
    },
    level: {
      type: String,
      default: 'campaign',
    },
    bugdetType: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
    platform: String,
  },
  data() {
    return {
      dialogVisible: false,
      formData: {
        dateVal: '',
        // taskType: '',
        budgetAmount: '',
      },
      // 设置不能选则当天之前的时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      loading: false,
      rules: {
        dateVal: { required: true, message: '请选择日期', trigger: 'blur' },
        // taskType: [{ required: true, message: '请选择开启时间', trigger: 'change' }],
        budgetAmount: { required: true, message: '请输入预算金额', trigger: 'blur' },
      },
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    adScheduleOpenTaskType() {
      return this.$store.state.initData.initData?.ad?.AdScheduleOpenTaskType || this.newAdScheduleOpenTaskType;
    },
  },
  methods: {
    show(v) {
      this.dialogVisible = true;
      if (v == 'mobile') {
        let budgetType = this.level == 'campaign' ? 'campaign' : this.level == 'adset' ? 'adGroup' : 'ad';
        this.formData.budgetAmount = this.row.ad[`${budgetType}DailyBudget`]
          ? this.row.ad[`${budgetType}DailyBudget`].toFixed(2)
          : this.row.ad[`${budgetType}LifetimeBudget`].toFixed(2);
      } else
        this.formData.budgetAmount = this.row.daily_budget
          ? (this.row.daily_budget / 100).toFixed(2)
          : (this.row.lifetime_budget / 100).toFixed(2);
    },
    hide() {
      this.dialogVisible = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let budgetAmount = null;
          let ids = null;
          budgetAmount = this.formData.budgetAmount;
          if (this.bugdetType == 'mobile') {
            ids =
              this.level == 'campaign'
                ? this.row.ad.campaignId
                : this.level == 'adset'
                ? this.row.ad.adGroupId
                : this.row.ad.adId;
          }
          let request = {
            adAccountId: this.$store.getters.accountId || this.row.ad.adAccountId,
            level: this.level,
            handleTime: this.formData.dateVal,
            id: this.bugdetType != 'mobile' ? this.row.id : ids,
            campaignId: this.row.campaignId,
            adGroupId: this.row.adGroupId,
            adGroupAdId: this.row.adGroupAdId,
          };
          if (
            this.row.daily_budget ||
            (this.row.ad && this.row.ad.campaignDailyBudget) ||
            (this.row.ad && this.row.ad.adGroupDailyBudget)
          ) {
            request.dailyBudget = budgetAmount;
          } else {
            request.lifetimeBudget = budgetAmount;
          }
          this.loading = true;
          //   if (this.bugdetType == 'mobile') {
          let obj = {
            gimpUserId: this.userInfo.data.id,
            thirdUserId:
              this.bugdetType == 'mobile'
                ? this.row.ad.credentialId && this.row.ad.credentialId.toString()
                : this.$store.state.num.selectedNum,
            platform: this.platform || this.row.ad.platform,
          };
          //   } else httpName = settingAdScheduleUpdateBudgetTask;
          settingAdScheduleUpdateBudgetTask(JSON.stringify(Object.assign(request, obj)))
            .then((res) => {
              this.loading = false;
              this.hide();
              if (res.code === 0) {
                this.$message.success('定时修改预算已打开！');
                if (this.bugdetType == 'mobile') {
                  this.$emit(
                    'settingCompleteMobile',
                    this.formData,
                    2,
                    this.level == 'campaign'
                      ? this.row.ad.campaignId
                      : this.level == 'adset'
                      ? this.row.ad.adGroupId
                      : this.row.ad.adId,
                    budgetAmount,
                  );
                } else {
                  this.$emit('settingComplete', this.formData, 2, this.row.id, budgetAmount);
                }
              }
            })
            .catch(() => {
              this.loading = false;
              this.hide();
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //处理输入框两位小数
    handleInput(value, arrName, keyName) {
      // console.log(value, arrName, keyName);
      this.$set(
        arrName,
        keyName,
        value
          .replace(/[^\d.]/g, '')
          .replace(/^\./g, '')
          .replace(/\.{2,}/g, '.')
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
          .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>
